import React from 'react';
import { useAuth } from '../services/auth.jsx';
import InstagramLoginButton from './InstagramLoginButton.jsx';
import { Navigate } from 'react-router-dom';

const Login = () => {
  const { user, loading, handleInstagramSuccess, handleInstagramFailure } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (user) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="login-container">
      <h1>Instagram Bot Service</h1>
      <InstagramLoginButton
        onSuccess={handleInstagramSuccess}
        onFailure={handleInstagramFailure}
      />
    </div>
  );
};

export default Login;
