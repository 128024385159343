import React from 'react';
import { useAuth } from '../services/auth.jsx';
import { Navigate } from 'react-router-dom';
import BotForm from './BotForm.jsx';
import Analytics from './Analytics.jsx';

const Dashboard = () => {
  const { user, loading, logout } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="dashboard-container">
      <header>
        <h1>Welcome, {user.instagramUsername}</h1>
        <button onClick={logout}>Logout</button>
      </header>
      <main>
        <BotForm userId={user.$id} />
        <Analytics userId={user.$id} />
      </main>
    </div>
  );
};

export default Dashboard;