import { Databases } from 'appwrite';
import client from './client';

const databases = new Databases(client);

export const createBot = async (userId, botData) => {
  return await databases.createDocument(
    'bots',
    'unique()',
    {
      userId,
      ...botData,
    }
  );
};

export const getUserBots = async (userId) => {
  return await databases.listDocuments(
    'bots',
    [
      Query.equal('userId', userId),
    ]
  );
};

export const updateBot = async (botId, botData) => {
  return await databases.updateDocument(
    'bots',
    botId,
    botData
  );
};

export const deleteBot = async (botId) => {
  return await databases.deleteDocument(
    'bots',
    botId
  );
};