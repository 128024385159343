import { Functions } from 'appwrite';
import client from './client';

const functions = new Functions(client);

export const createBot = async (botData) => {
  return await functions.createExecution(
    'createBot',
    JSON.stringify(botData)
  );
};

export const deductCredits = async (userId, amount) => {
  return await functions.createExecution(
    'deductCredits',
    JSON.stringify({ userId, amount })
  );
};