import React from 'react';
import { InstagramLogin } from '@amraneze/react-instagram-login';

const InstagramLoginButton = ({ onSuccess, onFailure }) => {
  const handleSuccess = (response) => {
    console.log(response);
    onSuccess(response);
  };

  const handleFailure = (response) => {
    console.error(response);
    onFailure(response);
  };

  return (
    <InstagramLogin
      clientId={import.meta.env.VITE_INSTAGRAM_CLIENT_ID}
      buttonText="Login with Instagram"
      onSuccess={handleSuccess}
      onFailure={handleFailure}
      redirectUri={import.meta.env.VITE_INSTAGRAM_REDIRECT_URI}
      useRedirect={true} // Use this if you want to handle redirect instead of popup
    />
  );
};

export default InstagramLoginButton;
