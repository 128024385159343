import React, { useEffect, useState } from 'react';
import { getUserBots } from '../services/bot.jsx';
import { getUserCredits } from '../services/payment.jsx';

const Analytics = () => {
  const [bots, setBots] = useState([]);
  const [credits, setCredits] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      const userBots = await getUserBots();
      setBots(userBots);
      const userCredits = await getUserCredits();
      setCredits(userCredits);
    };
    fetchData();
  }, []);

  const calculateRemainingTime = () => {
    const totalCostPerMinute = bots.length * 0.00011574;
    return credits / totalCostPerMinute;
  };

  return (
    <div className="analytics-container">
      <h2>Analytics</h2>
      <p>Active Bots: {bots.length}</p>
      <p>Remaining Credits: R${credits.toFixed(2)}</p>
      <p>Estimated Runtime: {calculateRemainingTime().toFixed(2)} minutes</p>
      <h3>Bot List</h3>
      <ul>
        {bots.map((bot) => (
          <li key={bot.$id}>
            <p>Post: {bot.postLink}</p>
            <p>Keyword: {bot.keyword}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Analytics;