import { Client } from 'appwrite';
import config from '../config';

const client = new Client();

client
  .setEndpoint('https://cloud.appwrite.io/v1')
  .setProject('66984db50024a809704b');


export default client;