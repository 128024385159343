import { createBot as createBotFunction } from '../appwrite/functions';
import { createBot as createBotDatabase, getUserBots as getUserBotsDatabase } from '../appwrite/database';

export const createBot = async (botData) => {
  try {
    const createdBot = await createBotDatabase(botData);
    await createBotFunction(createdBot);
    return createdBot;
  } catch (error) {
    console.error('Failed to create bot', error);
    throw error;
  }
};

export const getUserBots = async (userId) => {
  try {
    return await getUserBotsDatabase(userId);
  } catch (error) {
    console.error('Failed to get user bots', error);
    throw error;
  }
};