import React, { createContext, useContext, useState, useEffect } from "react";
import { Account, ID } from "appwrite";
import client from "../appwrite/client";

const AuthContext = createContext();

const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const account = new Account(client);

  useEffect(() => {
    checkUser();
  }, []);

  const checkUser = async () => {
    try {
      const currentUser = await account.get();
      setUser(currentUser);
    } catch (error) {
      if (error.code !== 401) {
        console.error("Error checking user:", error);
      }
      setUser(null);
    } finally {
      setLoading(false);
    }
  };

  const initiateInstagramLogin = () => {
    const clientId = import.meta.env.VITE_INSTAGRAM_CLIENT_ID;
    const redirectUri = import.meta.env.VITE_INSTAGRAM_REDIRECT_URI;
    window.location.href = `https://api.instagram.com/oauth/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=user_profile,user_media&response_type=code`;
  };

  const handleInstagramCallback = async (code) => {
    try {
      const response = await fetch('https://your-appwrite-endpoint/v1/functions/instagramAuth', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code }),
      });

      if (!response.ok) {
        throw new Error('Failed to exchange code for token');
      }

      const data = await response.json();
      const user = await account.create(ID.unique(), data.instagramId, data.instagramUsername, data.accessToken);

      setUser(user);
      return user;
    } catch (error) {
      console.error('Instagram authentication failed', error);
      throw error;
    }
  };

  const logout = async () => {
    try {
      await account.deleteSession("current");
      setUser(null);
    } catch (error) {
      console.error("Logout failed", error);
      throw error;
    }
  };

  const value = {
    user,
    loading,
    initiateInstagramLogin,
    handleInstagramCallback,
    logout,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const getCurrentUser = async () => {
  const account = new Account(client);
  try {
    const user = await account.get();
    return user;
  } catch (error) {
    console.error("Failed to get current user", error);
    return null;
  }
};

export { AuthContext, AuthProvider, useAuth, getCurrentUser };
