import { Databases } from 'appwrite';
import client from '../appwrite/client';
import { deductCredits as deductCreditsFunction } from '../appwrite/functions';

const databases = new Databases(client);

export const addCredits = async (userId, amount) => {
  try {
    const user = await databases.getDocument('users', userId);
    const newCredits = user.credits + amount;
    await databases.updateDocument('users', userId, { credits: newCredits });
    return newCredits;
  } catch (error) {
    console.error('Failed to add credits', error);
    throw error;
  }
};

export const deductCredits = async (userId, amount) => {
  try {
    await deductCreditsFunction(userId, amount);
  } catch (error) {
    console.error('Failed to deduct credits', error);
    throw error;
  }
};

export const getUserCredits = async (userId) => {
  try {
    const user = await databases.getDocument('users', userId);
    return user.credits;
  } catch (error) {
    console.error('Failed to get user credits', error);
    throw error;
  }
};