import React, { useState } from 'react';
import { createBot } from '../services/bot.jsx';

const BotForm = () => {
  const [postLink, setPostLink] = useState('');
  const [keyword, setKeyword] = useState('');
  const [commentReply, setCommentReply] = useState('');
  const [dmMessage, setDmMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    await createBot({
      postLink,
      keyword,
      commentReply,
      dmMessage,
    });
    // Reset form
    setPostLink('');
    setKeyword('');
    setCommentReply('');
    setDmMessage('');
  };

  return (
    <form onSubmit={handleSubmit} className="bot-form">
      <h2>Create New Bot</h2>
      <input
        type="text"
        placeholder="Post Link"
        value={postLink}
        onChange={(e) => setPostLink(e.target.value)}
        required
      />
      <input
        type="text"
        placeholder="Keyword"
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        required
      />
      <textarea
        placeholder="Comment Reply"
        value={commentReply}
        onChange={(e) => setCommentReply(e.target.value)}
        required
      />
      <textarea
        placeholder="DM Message"
        value={dmMessage}
        onChange={(e) => setDmMessage(e.target.value)}
        required
      />
      <button type="submit">Create Bot</button>
    </form>
  );
};

export default BotForm;